<template>
  <div class="home-internet">
    <PromoItem
      class="home-internet__head"
      :color="promo.color"
      :title="promo.title"
      :desc="promo.desc"
      :caption="promo.caption"
      :images="promo.images"
    />
    <div class="home-internet__body">
      <Collapse color="white">
        <template #head
          >Преимущества домашнего интернета для абонентов t2</template
        >
        <template #body>
          <div class="home-internet__grid">
            <ContentItem color="grey">
              <template #head>
                <img
                  src="@/assets/32/rouble.svg"
                  width="32"
                  height="32"
                  alt=""
                />
              </template>
              <template #body>
                <div class="home-internet__title">Удобная оплата</div>
                <p>
                  Домашний интернет для абонентов t2 можно добавить на тарифах
                  и&nbsp;оплачивать всё с единого счета в личном кабинете
                </p>
              </template>
            </ContentItem>
            <ContentItem color="grey">
              <template #head>
                <img
                  src="@/assets/32/settings.svg"
                  width="32"
                  height="32"
                  alt=""
                />
              </template>
              <template #body>
                <div class="home-internet__title">Конструктор</div>
              </template>
            </ContentItem>
            <ContentItem color="grey">
              <template #head>
                <img
                  src="@/assets/32/money.svg"
                  width="32"
                  height="32"
                  alt=""
                />
              </template>
              <template #body>
                <div class="home-internet__title">Экономия</div>
                <p>
                  Домашний интернет в составе пакетного тарифа дешевле,
                  чем&nbsp;отдельно
                </p>
              </template>
            </ContentItem>
          </div>
        </template>
      </Collapse>
      <Collapse color="white">
        <template #head>Как это работает</template>
        <template #body>
          <div class="home-internet__grid">
            <ContentItem color="grey">
              <template #head>
                <img
                  src="@/assets/32/rocket.svg"
                  width="32"
                  height="32"
                  alt=""
                />
              </template>
              <template #body>
                <p>Абонент выбирает скорость домашнего интернета и тариф</p>
              </template>
            </ContentItem>
            <ContentItem color="grey">
              <template #head>
                <img
                  src="@/assets/32/flash.svg"
                  width="32"
                  height="32"
                  alt=""
                />
              </template>
              <template #body>
                <p>
                  В зависимости от выбранной скорости интернета, увеличивается
                  абонентская плата по тарифу
                </p>
              </template>
            </ContentItem>
            <ContentItem color="grey">
              <template #head>
                <img src="@/assets/32/gift.svg" width="32" height="32" alt="" />
              </template>
              <template #body>
                <p>К тарифу бесплатно добавляется пакет минут и ГБ</p>
              </template>
            </ContentItem>
          </div>
          <div class="home-internet__text">
            Стоимость и доступность Домашнего интернета для абонентов t2 смотри
            на сайте t2 в разделе
            <a
              href="https://t2.ru/home-internet?pageParams=askForRegion%3Dtrue"
              target="_blank"
              >Домашний интернет</a
            >
          </div>
        </template>
      </Collapse>
      <Collapse color="white">
        <template #head>Как происходит подключение</template>
        <template #body>
          <div class="home-internet__grid home-internet__grid_4">
            <ContentItem color="grey">
              <template #head>1</template>
              <template #body>
                <p>
                  При подключении или настройке тарифа в WebDealer, в случае
                  наличия технической возможности подключения, создайте заявку
                  на добавление домашнего интернета
                </p>
              </template>
            </ContentItem>
            <ContentItem color="grey">
              <template #head>2</template>
              <template #body>
                <p>
                  Клиенту поступит звонок для уточнения деталей (когда удобно
                  провести монтаж, нужен ли роутер и т.д.)
                </p>
              </template>
            </ContentItem>
            <ContentItem color="grey">
              <template #head>3</template>
              <template #body>
                <p>
                  В назначенную дату к клиенту приходит монтажник и подключает
                  домашний интернет
                </p>
              </template>
            </ContentItem>
            <ContentItem color="grey">
              <template #head>4</template>
              <template #body>
                <p>
                  Клиент пользуется тарифом с мобильной связью и домашним
                  интернетом, оплачивая всё с единого счета
                </p>
              </template>
            </ContentItem>
          </div>
        </template>
      </Collapse>
      <Collapse color="white">
        <template #head>Алгоритм твоих действий</template>
        <template #body>
          <div class="home-internet__grid home-internet__grid_2">
            <ContentItem color="grey">
              <template #head>1</template>
              <template #body>
                <div class="home-internet__title">Расскажите про услугу</div>
                <p>
                  «К пакетному тарифу t2 можно добавить домашний интернет и
                  оплачивать всё с единого счета.<br />Цена будет составлять
                  всего + Х рублей к абонентской плате по тарифу»
                </p>
              </template>
            </ContentItem>
            <ContentItem color="grey">
              <template #head>2</template>
              <template #body>
                <div class="home-internet__title">
                  Проверьте техническую возможность подключения
                </div>
                <p>
                  Домашнего интернета для абонентов t2
                  <a
                    href="https://t2.ru/home-internet?pageParams=askForRegion%3Dtrue"
                    target="_blank"
                    >на сайте t2 в разделе Домашний интернет</a
                  >
                </p>
              </template>
            </ContentItem>
            <ContentItem color="grey">
              <template #head>3</template>
              <template #body>
                <div class="home-internet__title">
                  Если возможность подключения есть, проверьте тариф
                </div>
                <p>
                  На сайте
                  <a href="https://t2.ru" target="_blank">t2.ru</a>
                  можно увидеть перечень тарифов, на которых доступна услуга в
                  вашем регионе
                  <br />
                  Новый клиент: подключите или переведите нового клиента в t2 на
                  необходимый тариф
                  <br />
                  Действующий абонент t2: убедитесь, что у клиента необходимый
                  тариф для подключения (если нет, смените тариф)
                </p>
              </template>
            </ContentItem>
            <ContentItem color="grey">
              <template #head>4</template>
              <template #body>
                <div class="home-internet__title">Создайте заявку</div>
                <p>
                  На подключение Домашнего интернета для абонентов t2 в
                  WebDealer
                </p>
              </template>
            </ContentItem>
          </div>
        </template>
      </Collapse>
      <Collapse color="white">
        <template #head>Как создать заявку WebDealer</template>
        <template #body>
          <div class="home-internet__grid">
            <ContentItem color="grey">
              <template #head>1</template>
              <template #body>
                <img src="@/assets/web_dealer_1.png" alt="" />
                <p>
                  В карточке абонента в разделе «Операции»&nbsp;выберите
                  «Оформление заявки&nbsp;на&nbsp;подключение услуги ШПД»
                </p>
              </template>
            </ContentItem>
            <ContentItem color="grey">
              <template #head>2</template>
              <template #body>
                <img src="@/assets/web_dealer_2.png" alt="" />
                <p>
                  Введите адрес абонента и нажмите «Проверить&nbsp;возможность
                  подключения»
                </p>
              </template>
            </ContentItem>
            <ContentItem color="grey">
              <template #head>3</template>
              <template #body>
                <img src="@/assets/web_dealer_3.png" alt="" />
                <p>Нажмите «Оформить заявку»</p>
              </template>
            </ContentItem>
          </div>
        </template>
      </Collapse>
      <Collapse color="white">
        <template #head>Если нет технической возможности подключения</template>
        <template #body>
          <div class="home-internet__grid home-internet__grid_2">
            <ContentItem color="grey">
              <template #head>1</template>
              <template #body>
                <p>
                  Проинформируйте клиента, что система не видит технической
                  возможности подключения Домашнего интернета для абонентов t2
                </p>
              </template>
            </ContentItem>
            <ContentItem color="grey">
              <template #head>2</template>
              <template #body>
                <p>
                  Расскажите о специальном тарифе «Выгодное решение» или о
                  региональном промо-тарифе от Ростелеком, предложите
                  подключиться
                </p>
              </template>
            </ContentItem>
            <ContentItem color="grey">
              <template #head>3</template>
              <template #body>
                <p>
                  Если клиент согласен, проверьте техническую возможность и
                  составьте заявку&nbsp;в ЕИССД либо в&nbsp;Мобильном Агенте
                </p>
              </template>
            </ContentItem>
            <ContentItem color="grey">
              <template #head>4</template>
              <template #body>
                <p>
                  Если технической возможности нет, рекомендуйте клиенту
                  самостоятельно обратиться по бесплатному номеру
                  8&nbsp;800&nbsp;300&nbsp;66&nbsp;11 для уточнения
                  возможностей&nbsp;подключения Домашнего интернета для
                  абонентов t2
                </p>
              </template>
            </ContentItem>
          </div>
        </template>
      </Collapse>
    </div>
  </div>
</template>

<script setup>
import Collapse from "./Collapse.vue";
import ContentItem from "./ContentItem.vue";
import PromoItem from "./PromoItem.vue";

const promo = {
  color: "dark",
  desc: `<p>Предлагайте домашний интернет клиентам t2:</p>
  <ol>
    <li>В первую очередь рекомендуйте Домашний интернет для абонентов t2</li>
    <li>Если нет технической возможности для подключения, предложите домашний интернет от Ростелеком</li>
  </ol>
  `,
  images: [
    require("@/assets/home-internet/m.webp"),
    require("@/assets/home-internet/d.webp"),
  ],
};
</script>

<style lang="scss" scoped>
.home-internet {
  display: grid;
  gap: var(--widthGapH);
  &__body {
    display: grid;
    gap: 8px;
  }
  &__grid {
    display: grid;
    gap: var(--widthGapV);
    max-width: 1004px;
    @include md {
      grid-template-columns: repeat(3, 1fr);
    }
    &_2 {
      @include md {
        grid-template-columns: repeat(2, 1fr);
      }
    }
    &_4 {
      @include md {
        grid-template-columns: repeat(4, 1fr);
      }
    }
    &:deep(img) {
      width: auto;
    }
    &:deep(a) {
      color: inherit;
    }
  }
  &__title {
    font-size: 16px;
    line-height: 14px;
    font-weight: 800;
    font-family: var(--fontSecondary);
  }
  &__text {
    margin-top: var(--widthGapV);
    max-width: 560px;
    a {
      color: inherit;
    }
  }
}
</style>
