export default {
  namespaced: true,
  state: {
    dillers: {},
  },
  mutations: {
    setDillers(state, value) {
      state.dillers = value;
    },
  },
};
