const baseURL = process.env.VUE_APP_BASE_API || "";
const mediaURL = process.env.VUE_APP_MEDIA_DIR || "/media";

export default {
  computed: {
    mediaUrl() {
      return `${baseURL}${mediaURL}/`;
    },
  },
};
