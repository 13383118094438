<template>
  <div class="promo">
    <div class="promo__inner">
      <h1>
        локальная <br />
        цифровая папка <br />продавца
      </h1>
      <p>
        Вся актуальная <br />
        информация, собранная <br />в одном месте
      </p>
    </div>
  </div>
</template>

<script></script>
<style lang="scss" scoped>
.promo {
  color: var(--colorWhite);
  margin-top: var(--widthGapV);
  margin-bottom: var(--widthGapV);
  &__inner {
    max-width: var(--widthContainer);
    margin: 0 auto;
    width: 100%;
    background-color: var(--colorBlack);
    border-radius: var(--radiusInner);
    padding: var(--widthPadding);
    display: grid;
    align-content: start;
    gap: 8px;
    min-height: 256px;
    background-repeat: no-repeat;
    background-position: 100% 100%;
    background-image: url(@/assets/promo/m.webp);
    background-size: 305px auto;
    @include md {
      min-height: 360px;
      background-image: url(@/assets/promo/d.webp);
      background-size: 715px auto;
    }
    p {
      font-size: 12px;
      line-height: 16px;
      @include md {
        font-size: 15px;
        line-height: 22px;
        br {
          display: none;
        }
      }
    }
    h1 {
      font-size: 22px;
      line-height: 20px;
      @include md {
        font-size: 38px;
        line-height: 34px;
      }
    }
  }
}
</style>
