<template>
  <div class="join" v-if="store.state.sub.sub?.steps?.length">
    <Collapse
      v-for="(item, index) in store.state.sub.sub.steps"
      :key="index"
      color="grey"
    >
      <template #head>Шаг {{ index + 1 }}</template>
      <template #body>
        <h5 v-if="item.title" v-html="item.title"></h5>
        <div v-if="item.text" v-html="item.text"></div>
        <div v-if="item.explanation" v-html="item.explanation"></div>
      </template>
    </Collapse>
  </div>
</template>

<script setup>
import { useStore } from "vuex";
import Collapse from "./Collapse.vue";

const store = useStore();
</script>

<style lang="scss" scoped>
.join {
  display: grid;
  gap: 8px;
  &:deep() {
    h4,
    h5,
    p {
      margin-bottom: 8px;
    }
    strong {
      text-transform: none;
    }
    ul {
      li {
        img {
          height: auto;
          width: 70px;
          vertical-align: middle;
        }
      }
    }
  }
}
</style>
