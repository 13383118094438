<template>
  <div
    class="modal"
    tabindex="-1"
    aria-modal="true"
    role="dialog"
    @click="this.$emit('close')"
  >
    <div class="modal-dialog">
      <transition name="fade-out">
        <div class="modal-content">
          <div class="confirm-container" @click.stop="">
            <button
              class="confirm-container__close"
              @click="this.$emit('close')"
            ></button>
            <picture>
              <source
                srcset="@/assets/WorldMobile.svg"
                media="(max-width: 1024px)"
              />
              <img
                src="@/assets/World.svg"
                alt=""
                class="confirm-container__bg"
              />
            </picture>
            <div class="confirm-container__label">
              Ваш регион — {{ regionName }}?
            </div>
            <div class="confirm-container__buttons">
              <button @click="confirm(region.siteId)">Да</button>
              <button @click="this.$emit('changeRegion')">Нет</button>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
  <transition name="fade">
    <div class="modal-backdrop"></div>
  </transition>
</template>

<script>
import { mapMutations, mapState } from "vuex";
export default {
  name: "ModalRegionConfirm",
  emits: ["close", "changeRegion"],
  created() {
    document.body.classList.add("modal-open");
    document.addEventListener("keyup", this.keyPressEscape);
  },
  unmounted() {
    document.body.classList.remove("modal-open");
    document.removeEventListener("keyup", this.keyPressEscape);
  },
  computed: {
    ...mapState({
      region: (state) => state.api.regionConfirm,
    }),
    regionName() {
      return this.region?.name;
    },
  },
  methods: {
    ...mapMutations({
      setRegion: "region/setRegion",
    }),
    confirm(id) {
      this.setRegion(id);
      this.$emit("close");
    },
    keyPressEscape(e) {
      if (e.key === "Escape") {
        this.$emit("close");
      }
    },
  },
};
</script>

<style scoped lang="scss">
.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5055;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  &-dialog {
    position: relative;
    width: auto;
    pointer-events: none;
    display: flex;
    min-height: 100%;
    margin: 0 auto;
  }
  &-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    outline: 0;
    color: #fff;
    @include lg {
      background-color: rgb(31, 34, 41, 0.95);
    }
  }
  &-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5050;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    opacity: 0.5;
  }
  .confirm-container {
    width: 345px;
    height: 180px;
    border-radius: 8px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: var(--colorPrimaryBg);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 8px;

    @include lg {
      width: 600px;
      height: 280px;
      border-radius: 16px;
    }

    &__close {
      @include reset-button;
      background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M17.7911.2094a.709.709 0 0 0-1.0027 0L9 7.9978 1.2116.2094A.709.709 0 1 0 .2089 1.2121l7.7884 7.7884L.209 16.7889a.709.709 0 0 0 1.0027 1.0027L9 10.0032l7.7884 7.7884a.709.709 0 1 0 1.0027-1.0027l-7.7884-7.7884 7.7884-7.7884a.709.709 0 0 0 0-1.0027Z' fill='%23000'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: 50% 50%;
      position: absolute;
      width: 12px;
      height: 12px;
      right: 12px;
      top: 12px;

      @include lg {
        width: 18px;
        height: 18px;
        right: 24px;
        top: 24px;
      }
    }
    &__bg {
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: -1;
    }

    &__label {
      font-size: 18px;
      line-height: 140%;
      color: var(--colorPrimaryText);
      @include lg {
        font-size: 22px;
      }
    }
    &__buttons {
      margin-top: 24px;
      display: flex;
      button:first-of-type {
        margin-right: 8px;
      }

      @include lg {
        margin-top: 34px;
        button:first-of-type {
          margin-right: 10px;
        }
      }

      button {
        @include reset-button;
        width: 94px;
        height: 32px;
        border-radius: 4px;
        font-size: 14px;
        line-height: 18px;
        &:first-of-type {
          background: #1f2121;
        }
        &:last-of-type {
          background: #ffffff;
          color: #1f2229;
        }

        @include lg {
          width: 125px;
          height: 45px;
          font-size: 16px;
          border-radius: 6px;
        }
      }
    }
  }
}
</style>
