export default {
  namespaced: true,
  state: {
    list: [
      {
        icon: "nav/1.svg",
        name: "Преимущества t2",
        section: "#advantages",
      },
      {
        icon: "nav/2.svg",
        name: "Тарифы",
        section: "#tariffs",
      },
      {
        icon: "nav/3.svg",
        name: "Акции для&nbsp;абонентов",
        section: "#offers",
      },
      {
        icon: "nav/4.svg",
        name: "Бонусы для&nbsp;продавцов",
        section: "#bonuses",
      },
      {
        icon: "nav/5.svg",
        name: "Приложения t2",
        section: "#apps",
      },
      {
        icon: "nav/6.svg",
        name: "Технология eSIM",
        section: "#technology",
      },
      {
        icon: "nav/7.svg",
        name: "Домашний интернет",
        section: "#home_internet",
      },
      {
        icon: "nav/8.svg",
        name: "Подключение нового абонента",
        section: "#join",
      },
      {
        icon: "nav/9.svg",
        name: "Обновление ПД",
        section: "#personal",
      },
      {
        icon: "nav/10.svg",
        name: "Карта покрытия",
        section: "#map",
      },
    ],
  },
};
