import { createStore } from "vuex";

import api from "./api";
import apps from "./apps";
import device from "./device";
import dillers from "./dillers";
import esim from "./esim";
import faq from "./faq";
import nav from "./nav";
import notifications from "./notifications";
import promo from "./promo";
import region from "./region";
import selfReg from "./selfReg";
import sellers from "./sellers";
import sub from "./sub";

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    api,
    device,
    nav,
    region,
    notifications,
    promo,
    esim,
    sub,
    apps,
    sellers,
    dillers,
    selfReg,
    faq,
  },
});
